import * as React from "react";

import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { Avatar, Card, SEO, SiteLink, notEmpty } from "@parataxic/shared-ui";

import { PsychologistTagList } from "../components/Psychologist";

import "./Theory.css";

type Theory = GatsbyTypes.MdxTheoryEdge["node"];

const TheoryItem: React.FC<{ theory: Theory }> = ({ theory }) => {
  const manyAuthor = false;
  return (
    <Card
      className={`theoryCard ${manyAuthor ?? "manyAuthor"}`}
      role="listitem"
      variant="elevated"
    >
      <Avatar
        title={theory.authors[0].handle}
        style={{ marginRight: ".5rem" }}
        borderRadius=".25rem"
        image={getImage(theory.authors[0]?.avatarImageSrc)}
        alt={theory.authors[0]?.fullName}
      />
      <span>
        <SiteLink
          href={theory.slug}
          style={{
            fontSize: ".95rem",
            justifyContent: "space-between",
          }}
        >
          {theory.title}
        </SiteLink>
        <div>
          <PsychologistTagList
            persons={theory.authors}
            delimiter=""
            showPopover={false}
            showAvatar={false}
            usePopup
            popupTitle={`Authors of ${theory.title}`}
            linkProps={{
              style: {
                fontSize: ".85rem",
              },
            }}
          />
        </div>
      </span>
    </Card>
  );
};

const sortSchools = (
  a: Pick<GatsbyTypes.MdxTheoryGroupConnection, "fieldValue">,
  b: Pick<GatsbyTypes.MdxTheoryGroupConnection, "fieldValue">,
) => {
  const order = [
    "Classical psychoanalysis",
    "Psychodynamic",
    "Object relations",
    "Family systems",
    "Humanistic",
    "Cognitive",
    "Other",
  ];
  return (
    order.indexOf(a.fieldValue as string) -
    order.indexOf(b.fieldValue as string)
  );
};

const sortTheories = (
  a: Pick<GatsbyTypes.MdxTheory, "id">,
  b: Pick<GatsbyTypes.MdxTheory, "id">,
) => {
  const order = [
    // classical psychoanalysis
    "freud",
    "arlow",

    // psychodynamic
    "adler",
    "jung",
    "fromm",
    "horney",
    "sullivan",
    "erikson",
    "attachment-theory",

    // object relations
    "klein",
    "bion",
    "fairbairn",
    "guntrip",
    "winnicott",
    "kernberg",
    "kohut",
  ];
  return order.indexOf(a.id) - order.indexOf(b.id);
};

interface ITheoryViewProps {
  data: GatsbyTypes.TheoryListQuery;
}

const TheoryView: React.FC<ITheoryViewProps> = ({ data }) => {
  const theories = data.allMdxTheory.group
    .map((ok) => ok) // just for typing
    .sort(sortSchools);
  const initialItems = React.useMemo<GatsbyTypes.TheoryListQuery[]>(
    () => data.allMdxTheory.group.map((group) => group).sort(sortSchools),
    [theories],
  );

  const [items, setItems] = React.useState(initialItems);

  /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
  React.useEffect(
    () => setItems(initialItems.filter(notEmpty).sort(sortSchools)),
    [data.allMdxTheory],
  );

  return (
    <>
      <SEO
        title="Studying theories, object relations and family systems frameworks"
        description="Psychology knowledge buffet. Get in and gorge."
      />
      <div align="center">
        parataxic is a website for studying papers on theories of behavior and
        personality.
      </div>
      <div>
        {items
          .filter((group) => group.edges.length > 0)
          .map((group, index) => (
            <div key={index} role="group" style={{ style: "1rem" }}>
              <h2
                style={{
                  fontSize: "var(--fontSizes-md)",
                  paddingInlineStart: "1rem",
                }}
              >
                {group.fieldValue}
              </h2>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(330px, 1fr))", // 100% on mobile
                  gridGap: "var(--space-6)",
                  maxWidth: "1000px",
                  margin: "0 auto",
                  padding: "0rem 1rem 0 1rem",
                  paddingInlineStart: "1rem",
                  paddingInlineEnd: "1rem",
                  ...(group.edges.length === 1
                    ? {
                        width: { base: "100%", sm: "50%" },
                      }
                    : {}),
                }}
              >
                {group.edges
                  .map(({ node }) => node)
                  .sort(sortTheories)
                  .map((theory, index2: number) => (
                    <TheoryItem theory={theory} key={index2} />
                  ))}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export const _query = graphql`
  fragment PsychologistAvatar on MdxPsychologist {
    id
    fullName
    firstName
    lastName
    middleName
    handle
    birthDay(formatString: "YYYY")
    deathDay(formatString: "YYYY")
    slug
    knownFor {
      id
      slug
      title
    }
    avatarImageSrc {
      childImageSharp {
        gatsbyImageData(
          width: 50
          placeholder: BLURRED
          layout: FIXED
          formats: [WEBP]
        )
      }
    }
  }

  fragment TheoryFragment on MdxTheory {
    id
    slug
    title
    slug
    school {
      id
      name
    }
    authors {
      ...PsychologistAvatar
    }
  }

  query TheoryList {
    allMdxTheory {
      group(field: school___name) {
        field
        fieldValue
        totalCount
        edges {
          node {
            ...TheoryFragment
          }
        }
      }
    }
  }
`;

export default TheoryView;
